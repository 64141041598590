import React from "react"
import { Date } from 'utils'
import Link from 'components/link'

export default featuredPost => {
    const {
        title,
        excerpt,
        link,
        newsFields,
        featuredImage
    } = featuredPost.featuredPost

    const _featuredImage = featuredImage ? featuredImage.node.mediaItemUrl : ''

    const formattedDate = <Date date={newsFields.date} includeYear="true" />

    return (
        <div className="news-hero">
            <div className="news-hero-image" style={_featuredImage ? { backgroundImage: `url(${_featuredImage})` } : { backgroundImage: '' }} />
            <div className="news-hero-details-cont">
                <div className="news-hero-details">
                    <span>{formattedDate}</span>
                    <Link to={link}>
                        <h1 dangerouslySetInnerHTML={{ __html: title }} />
                        <div className="news-hero-excerpt" dangerouslySetInnerHTML={{ __html: excerpt }} />
                    </Link>
                    <Link 
                        to={link} 
                        dangerouslySetInnerHTML={{ __html: 'Read more' }} 
                        className="button" 
                    />
                </div>
            </div>
        </div>
    )
}
