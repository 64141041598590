import React, { useState } from "react"
import { graphql } from "gatsby"
import { useLazyQuery } from '@apollo/client'
import { GET_NEWS_BY_CAT } from '../graphql/queries'

import Layout from "../components/layout"
import SEO from "../components/seo"
import NewsHero from "../components/newsHero"
import NewsGrid from "../components/newsGrid"
import NewsGridFeatured from "../components/newsGridFeatured"

export default ({ location, data }) => {
    const [selectedCat, setSelectedCat] = useState('')
    const [getNewsByCat, { data: postsData, loading: postsLoading }] = useLazyQuery(GET_NEWS_BY_CAT)
    const [overrideLoading, setOverrideLoading] = useState(false)

    const handleChangeCat = slug => {
        setOverrideLoading(true)

        setTimeout(() => {
            setOverrideLoading(false)
        }, 200)

        setTimeout(() => {
            getNewsByCat({
                variables: {
                    category: slug
                },
            })
        }, 50)

        setSelectedCat(slug)
    }

    const {
        posts: {
            edges: posts
        },
        page: {
            newsPageFields: {
                featuredPosts: _featuredPosts
            }
        }
    } = data.siteFields

    const postsNodesArray = postsData ? postsData.posts.edges : posts

    const dateSort = array => array.sort((a, b) => {
        return new Date(b.newsFields.date) - new Date(a.newsFields.date)
    })

    let _posts = dateSort(postsNodesArray.map(post => post.node))
    let _allPosts = dateSort(posts.map(post => post.node))

    const isLoading = overrideLoading || postsLoading

    if (selectedCat === '') {
        _posts = posts.map(post => post.node)
    }

    let _notFeaturedPosts = []
    let _featuredPost = []

    _allPosts.forEach(postRef => {
        const isFeatured = _featuredPosts.find(({ post }) => {
            return post.id === postRef.id
        })
        if (isFeatured) {
            _featuredPost.push(postRef)
        } else {
            _notFeaturedPosts.push(postRef)
        }
    })

    let firstSixPosts = null
    let featuredPosts = null
    let remainingPosts = null

    if (!selectedCat) {
        firstSixPosts = _notFeaturedPosts.slice(0, 6)
        featuredPosts = _notFeaturedPosts.slice(6, 8)
        remainingPosts = _notFeaturedPosts.slice(8)
    }

    return (
        <Layout location={location}>
            <SEO title="News &amp; Stories" description="Pentridge - News &amp; Stories" />
            {_featuredPost[0] ? <NewsHero featuredPost={_featuredPost[0]} /> : ''}
            {firstSixPosts ?
                <>
                    <NewsGrid
                        posts={firstSixPosts}
                        selectedCat={selectedCat}
                        handleChangeCat={handleChangeCat}
                        contentVisible={!isLoading}
                    />
                    {featuredPosts &&
                        <NewsGridFeatured
                            posts={featuredPosts}
                            contentVisible={!isLoading}
                        />
                    }
                    {remainingPosts &&
                        <NewsGrid
                            posts={remainingPosts}
                            contentVisible={!isLoading}
                            hideFilter="true"
                        />
                    }
                </>
                :
                <NewsGrid
                    posts={_posts}
                    selectedCat={selectedCat}
                    handleChangeCat={handleChangeCat}
                    contentVisible={!isLoading}
                />
            }
        </Layout>
    )
}


export const query = graphql`
    query($databaseId: ID!) {
        siteFields {
            page(id: $databaseId, idType: DATABASE_ID) {
                id
                databaseId
                newsPageFields {
                    featuredPosts {
                        post {
                            ...on WPGraphQL_Post {
                                id
                                databaseId
                            }
                        }
                    }
                }
            }
            posts (first: 500) {
                edges {
                    node {
                        id
                        databaseId
                        title
                        link
                        slug
                        date
                        excerpt
                        content(format: RENDERED)
                        featuredImage {
                            node {
                                mediaItemUrl
                            }
                        }
                        newsFields {
                            date
                            excerpt
                        }
                        categories {
                            edges {
                                node {
                                    name
                                }
                            }
                        }
                        tags {
                            edges {
                                node {
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
