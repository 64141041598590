import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default ({ selectedCat, handleChangeCat }) => {

    const catsVar = useStaticQuery(graphql`
        query catsVar {
            siteFields {
                categories {
                    nodes {
                        name
                        slug
                    }
                }
            }
        }
    `)

    const {
        nodes: cats
    } = catsVar.siteFields.categories

    let _cats = [{
        slug: '',
        name: 'All'
    }].concat(cats)

    _cats = _cats.filter(function(item) {
        return item.slug !== 'uncategorised'
    })

    return (

        <div className="news-filter">

            <div className="container">

                <ul>

                    {_cats.map((row, index) => {

                        const {
                            slug,
                            name
                        } = row

                        return (

                            <li key={index} data-name={slug} className={slug === selectedCat ? 'active' : '' }>

                                <button onClick={() => handleChangeCat(slug)} onKeyDown={() => handleChangeCat(slug)}>

                                    <span dangerouslySetInnerHTML={{ __html: name }} />

                                </button>

                            </li>

                        )

                    })}
                
                </ul>

            </div>

        </div>

    )
}
