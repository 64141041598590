import React from "react"
import NewsItem from 'components/newsItem'

export default ({ posts, contentVisible }) => {

    return (

        <>

            <div className={`newsGrid featured ${ contentVisible ? '' : 'loading'}`}>

                <div className="container">

                    { posts.length > 0 ?

                        <ul>

                            {posts.map((row, index) => {

                                return (

                                    <li key={index}>

                                        <NewsItem item={ row } />

                                    </li>

                                )

                            })}

                        </ul>

                    : 'Sorry, there are no matching posts' }

                </div>

            </div>

        </>

    )
}
